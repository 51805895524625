export enum PasswordRequirementsText {
  passwordLength = 'Be at least 8 characters in length',
  newPasswordNotDifferent = 'Different from your last 5 passwords',
  uniqueCharacters = 'At least 5 characters have to be unique',
  commonPassword = 'Must not be an extremely common password'
}

export enum PasswordValidationLabels {
  passwordLength = 'passwordLength',
  uniqueCharacters = 'uniqueCharacters',
  newPasswordNotDifferent = 'newPasswordNotDifferent',
  commonPassword = 'commonPassword',
}

export enum ApiPasswordErrorMessageMapping {
  commonPassword = 'common password',
  newPasswordNotDifferent = 'used in the last'
}
