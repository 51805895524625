
  <!-- Valid Token Section -->
  <ng-container 
  [formGroup]="form"
  id="brandedPasswordRequirements"
  [class]="containerClass">
    <!-- Password Requirements for Reset Password -->
    <aside 
      id="passwordInstructions"
      [class.center-requirements]="centerRequirements">
      <h3 class="reset-password-instructions-header">Your new password must be:</h3>
      <ul class="password-requirement-list" *ngIf="(_passValidationSubscription | async) as passwordRequirementsWithValidation">
        <li 
          *ngFor="let passwordRequirement of passwordRequirementsWithValidation;"
          class="password-requirement-list-item"
          [class.is-invalid]="passwordRequirement.valid === false"
        >
          <div class="password-requirement-list-item-content">
            {{passwordRequirement.requirement}}
            <zbp-icon 
              *ngIf="passwordRequirement.valid" [iconName]="iconNames.check_mark"
              class="valid-requirement"
              iconSize="font-size">
            </zbp-icon>
            <zbp-icon 
              *ngIf="passwordRequirement.valid === false" [iconName]="iconNames.x_cross"
              class="invalid-requirement"
              iconSize="font-size">
            </zbp-icon>
          </div>
        </li>
      </ul>
    </aside>

    <!-- New Password -->
  <div class="row input-group mx-auto my-2 w-100">
    <label for="password" class="form-label ">
      Password
    </label>
    <div class="account-password-group m-0 p-0">
      <input id="password"
              [type]="showPassword ? 'text' : 'password'"
              [name]="formControlNames.Password"
              required
              class="form-control"
              autocomplete="off"
              [formControlName]="formControlNames.Password"
              [class.is-invalid]="!isPasswordControlValid()"
      />
      <button id="showPasswordButton"
              type="button"
              class="p-0 m-0 h-100 show-password-button"
              [class.is-invalid]="!isPasswordControlValid()"
              [attr.aria-label]="'Show password'"
              (click)="toggleShowPassword()">
        <i class="fa"
            [class.fa-eye-slash]="!showPassword"
            [class.fa-eye]="showPassword">
        </i>
      </button>
    </div>
  </div>

    <!-- Confirm Password -->
  <div class="row input-group mx-auto my-2 w-100">
    <label for="confirmPassword"
            class="form-label ">
      Confirm Password
    </label>
    <div class="account-password-group m-0 p-0">
      <input id="confirmPassword"
              [type]="showConfirmPassword ? 'text' : 'password'"
              [name]="formControlNames.ConfirmPassword"
              required
              class="form-control"
              autocomplete="off"
              [formControlName]="formControlNames.ConfirmPassword"
              [class.is-invalid]="!isConfirmPasswordValid()"
      />
      <button id="showConfirmPasswordButton"
              class="p-0 m-0 h-100 show-password-button"
              type="button"
              [class.is-invalid]="!isConfirmPasswordValid()"
              [attr.aria-label]="'Show password'"
              (click)="toggleShowConfirmPassword()">
        <i class="fa"
            [class.fa-eye-slash]="!showConfirmPassword"
            [class.fa-eye]="showConfirmPassword">
        </i>
      </button>
    </div>
    <div class="invalid-feedback" [class.d-block]="!isConfirmPasswordValid()">
      Password does not match retyped password.
    </div>
  </div>

</ng-container>

