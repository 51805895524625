import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { AuthService } from '@core/auth.service';
import { BrowserService } from '@core/browser.service';
import { UserService } from '@core/user.service';
import { ApiResponse } from '@models/api-response';
import { ApplicationUser } from '@models/application-user';
import { IUser } from '@models/user';
import { FormHelpers } from '@shared/form-helpers';
import { copyObject } from '@shared/zb-object-helper/object-helper';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'zbp-setup-account-password',
  templateUrl: './setup-account-password.component.html',
  styleUrls: ['../login.component.scss', './setup-account-password.component.scss'],
})
export class SetupAccountPasswordComponent implements OnInit {
  private incomingUserId: string;
  private token: string;

  inAccountSetup = false;
  inResetPassword = false;
  formErrors: string[] = [];
  form: FormGroup;
  user: IUser;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService,
    public browserService: BrowserService) {
    this.form = new FormGroup({
      password: new FormControl('', FormHelpers.getPasswordValidator()),
      confirmPassword: new FormControl('', FormHelpers.getConfirmPasswordValidator()),
    });
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(
        mergeMap((queryParams: Params) => {
          this.inAccountSetup = this.router.url.includes('/account-setup');
          this.inResetPassword = this.router.url.includes('set-password');
          if (queryParams.token && queryParams.userid && !this.userService.userId) {
            // The query parameter from the Api does not actually have the same encoding as in JavaScript despite what
            // the .NET documentation suggests, but we can still use decodeURIComponent.
            this.token = decodeURIComponent(queryParams.token);
            this.incomingUserId = queryParams.userid;
          } else if (this.userService.userId) {
            this.form.addControl('currentPassword', new FormControl(''));
          } else {
            return of(new ApiResponse<IUser>(false, {
              response: null,
              messages: ['Please use a valid reset password link.'],
            }));
          }

          if (this.inAccountSetup || this.inResetPassword) {
            return this.userService.getAccountSetupInfo(this.incomingUserId, this.token, this.inResetPassword)
              .pipe(
                map((res) => {
                  if (res.success) {
                    // Replaces query parameter token with a password reset one.
                    this.token = res.response.passwordResetToken;
                    return new ApiResponse<IUser>(true, {
                      response: copyObject({
                        ...res.response,
                        userId: this.incomingUserId,
                      }, ApplicationUser),
                      messages: res.messages,
                    });
                  }
                  return new ApiResponse<IUser>(false, { ...res });
                })
              );
          }
          return of(new ApiResponse<IUser>(true, { response: this.userService.user, messages: [] }));
        })
      )
      .subscribe((res: ApiResponse<IUser>) => {
        if (res.success) {
          this.user = res.response;
        } else {
          const errorMessage = res.messages && res.messages.length > 0
            ? res.messages[0]
            : 'Please use a valid reset password link.';
          this.toastr.error(errorMessage);
          this.router.navigateByUrl('/login/user');
        }
      });
  }

  submitForm(): void {
    this.formErrors = [];
    if (this.form.valid) {
      const args: string[] = [];
      const userId = this.incomingUserId ? this.incomingUserId : this.userService.userId;

      if (this.token) {
        args.push(null, this.token);
      } else {
        args.push(this.form.value.currentPassword);
      }

      // Save the password and redirect to login after logging out.
      this.userService.updateUserPassword(userId, this.form.value.password, ...args)
        .subscribe((res: ApiResponse<boolean>) => {
          if (res.success) {
            if (res.messages.length === 0) {
              this.authService.logout();
              this.toastr.success(
                'You have successfully changed your password. Please use your new password to log in.');
              this.router.navigateByUrl('/login/user');
            } else {
              res.messages.forEach(m => this.toastr.error(m));
              if (!res.response) {
                this.router.navigateByUrl('/invalid-token');
              }
            }
          } else {
            this.toastr.error('An unknown error has occurred attempting to save your new password.');
            this.router.navigateByUrl('/invalid-token');
          }
        });
    } else {
      this.formErrors = FormHelpers.getAllFormErrors(this.form);
    }
  }

  isControlValid(name: string): boolean {
    return this.form.controls[name] && (!this.form.controls[name].touched || this.form.controls[name].valid);
  }

  passwordMeetsRequirement(key: string): boolean {
    return !this.form.controls.password.errors || !this.form.controls.password.errors[key];
  }

  getPasswordRequirementClasses(key: string): any {
    if (this.form.controls.password.pristine
      || !this.form.controls.password.touched
      || !this.form.controls.password.value) {
      return {};
    }

    const valid = this.passwordMeetsRequirement(key);
    return {
      'fa-check': valid,
      'fa-times': !valid,
      'text-success': valid,
      'text-danger': !valid,
    };
  }
}
