import { Component } from '@angular/core';
import { CompanyCode } from '@shared/enums/company-code';
import { ViewHandlerComponent } from '@shared/view-handler/view-handler.component';
import { LoginUserHandlerComponent } from '../../login-user-handler/login-user-handler.component';
import { BrandedLoginComponent } from '../branded-login.component';

@Component({
  selector: 'zbp-login-redesign-handler',
  template: '<ng-container *ngComponentOutlet="componentToUse | async"></ng-container>',
})
export class Login2024RedesignHandlerComponent extends ViewHandlerComponent {
  componentMap = {
    [CompanyCode.HighlightsPortal]: {
      component: BrandedLoginComponent,
    },
    [CompanyCode.ZBPortal]: {
      component: LoginUserHandlerComponent,
    },
    ZbRedesign: {
      component: BrandedLoginComponent,
    }
  };
}
