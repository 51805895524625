import { PrimaryMenu } from '@models/primary-menu';
import { RoleType } from '@shared/enums/role-type';
import { hlContactUsMenu, zbContactUsMenu } from './contact-us-menu';

export const SCHOOL_ADMIN_MANAGE_MENU_ID = 'SchoolManage';

export const schoolHLAdminMainMenus: PrimaryMenu[] = [
  hlContactUsMenu,
  { id: SCHOOL_ADMIN_MANAGE_MENU_ID, isRouted: false, isExternal: false, label: 'Manage', weight: -10 },
];

export const schoolZBAdminMainMenus: PrimaryMenu[] = [
  zbContactUsMenu,
  { id: SCHOOL_ADMIN_MANAGE_MENU_ID, isRouted: false, isExternal: false, label: 'Manage', weight: -10 },
];

export const schoolAdminManageDropdownMenus: PrimaryMenu[] = [
  {
    id: 'manage-users',
    isRouted: true,
    route: 'users',
    isExternal: false,
    label: 'Manage Users',
    weight: 1,
    roleType: RoleType.SchoolAdministrator,
  },
  {
    id: 'assign-revoke-licenses',
    isRouted: true,
    isExternal: false,
    route: 'licenses',
    label: 'Assign and Revoke Licenses',
    weight: 2,
    roleType: RoleType.SchoolAdministrator,
  },
  {
    id: 'class-user-statistics',
    isRouted: true,
    isExternal: false,
    route: '/overview',
    label: 'Class and User Statistics',
    weight: 3,
    roleType: RoleType.SchoolAdministrator,
  },
];

export const schoolAdminManageDropdownMenusHighlightsPortal = schoolAdminManageDropdownMenus.filter(menu => (
  !['class-user-statistics', 'manage-classes'].includes(menu.id)
));

export function buildSchoolAdminZBRedesignMenus(needsSchoolId: boolean, schoolId: string = null): PrimaryMenu[] {
  return [
    {
      id: 'overview',
      isRouted: true,
      route: 'overview',
      isExternal: false,
      label: 'Overview',
      weight: 1,
      roleType: RoleType.SchoolAdministrator,
      schoolId,
    },
    {
      id: 'manage-users',
      isRouted: true,
      route: 'users',
      isExternal: false,
      label: 'Manage users',
      weight: 2,
      roleType: RoleType.SchoolAdministrator,
      disabled: needsSchoolId,
      tooltip: needsSchoolId ? 'These links are disabled until a school ID has been set' : '',
      schoolId,
    },
    {
      id: 'classrooms',
      isRouted: true,
      isExternal: false,
      route: 'classrooms',
      label: 'Manage classes',
      weight: 3,
      roleType: RoleType.SchoolAdministrator,
      disabled: needsSchoolId,
      tooltip: needsSchoolId ? 'These links are disabled until a school ID has been set' : '',
      schoolId,
    },
    {
      id: 'assign-revoke-licenses',
      isRouted: true,
      isExternal: false,
      route: 'licenses',
      label: 'Assign and revoke licenses',
      weight: 4,
      roleType: RoleType.SchoolAdministrator,
      disabled: needsSchoolId,
      tooltip: needsSchoolId ? 'These links are disabled until a school ID has been set' : '',
      schoolId,
    },
    {
      id: 'class-user-statistics',
      isRouted: true,
      isExternal: false,
      route: 'reports',
      label: 'Class and user statistics',
      weight: 7,
      roleType: RoleType.SchoolAdministrator,
      disabled: needsSchoolId,
      tooltip: needsSchoolId ? 'These links are disabled until a school ID has been set' : '',
      schoolId,
    },
  ];
}
