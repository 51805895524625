import { Component } from '@angular/core';
import { CompanyCode } from '@shared/enums/company-code';
import { ViewHandlerComponent } from '@shared/view-handler/view-handler.component';
import { BrandedLoginComponent } from '../../branded-login/branded-login.component';
import { ChooseRoleComponent } from '../choose-role.component';
import { RedesignedChooseRoleComponent } from '../redesigned-choose-role/redesigned-choose-role.component';

@Component({
  selector: 'zbp-choose-role-redesign-handler',
  template: '<ng-container *ngComponentOutlet="componentToUse | async"></ng-container>',
})
export class ChooseRoleRedesignHandlerComponent extends ViewHandlerComponent {
  componentMap = {
    [CompanyCode.HighlightsPortal]: {
      component: BrandedLoginComponent,
    },
    [CompanyCode.ZBPortal]: {
      component: ChooseRoleComponent,
    },
    ZbRedesign: {
      component: RedesignedChooseRoleComponent,
    }
  };
}
