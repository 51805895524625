import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';

import { IUser } from '@models/user';
import { RoleType } from '@shared/enums/role-type';
import { Observable, of } from 'rxjs';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanActivateChild {
  constructor(private userService: UserService) { }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const roles = route.data.roles ? route.data.roles : [];
    return of(this.userInRoles(roles, this.userService.user));
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  userInRoles(roles: RoleType[], user: IUser): boolean {
    if (roles.length === 0) {
      return true;
    }

    return user.roles.findIndex((role: RoleType) => roles.includes(role)) !== -1;
  }
}
