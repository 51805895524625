import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigService } from '@core/appconfig.service';
import { AuthService } from '@core/auth.service';
import { CacheService } from '@core/cache.service';
import { UserService } from '@core/user.service';
import { ApiResponse } from '@models/api-response';
import { OauthResponse } from '@models/oauth-response';
import { UserStatusResponse } from '@models/user-response';
import { OAuth2Provider } from '@shared/enums/oauth2-provider.enum';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OAuth2Service {
  constructor(
    private http: HttpClient,
    private cache: CacheService,
    private authService: AuthService,
    private userService: UserService,
    private appConfig: AppConfigService,
  ) { }

  private getLoginUrl(): string {
    return `${this.appConfig.apiUrl}/oauth2`;
  }

  getSsoUrl(provider: string): Observable<OauthResponse> {
    return this.http.get<OauthResponse>(`${this.getLoginUrl()}/login?vendor=${provider}`);
  }

  /**
   * Gets the OAuth2 Provider enum by key.
   *
   * @param {String} providerName The provider name.
   *
   * @returns {OAuth2Provider} The oauth2 provider defaulting to clever if not provided (for legacy endpoint).
   */
  getProvider(providerName: string = null): OAuth2Provider {
    return (providerName && OAuth2Provider[providerName]) ? OAuth2Provider[providerName] : OAuth2Provider.clever;
  }

  /**
   * Authorizes user based on provider and oauth2 authentication token.
   *
   * @param {OAuth2Provider} provider The provider key "clever", "google", etc...
   * @param {String} code The authentication token.
   * @param {?String} state Optional state string.
   */
  login(provider: OAuth2Provider, code: string, state?: string): Observable<ApiResponse<UserStatusResponse>> {
    // Makes a request to ZbPortal-Api
    // if logged in, then either use a stored existing clever access token, or refresh / get a  new one if expired
    // if not logged in, get a new clever access token.
    // both should return UserStatusResponse.
    return this.http.post(this.getLoginUrl(), { provider, code, state })
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, { ...res })),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<UserStatusResponse>(false, { ...err.error }));
        }),
        map((res) => {
          if (res.success) {
            this.cache.coreApiToken = res.response.token;
            this.userService.setUserData(res.response);
            this.authService.authStatus.next(true);
          }
          return res;
        }),
      );
  }

}
