import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AppConfigService } from './appconfig.service';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private appConfig: AppConfigService,
    private authService: AuthService,
    private userService: UserService,
  ) {}

  /**
   * Intercepts HTTP requests and responses, modifying the request object before it is sent and applying error handling
   * on the response.
   *
   * @param request {HttpRequest<any>} - The outgoing request object to be intercepted
   * @param next {HttpHandler} - The next HttpHandler in the middleware chain
   *
   * @returns Observable<HttpEvent<any>> - An Observable event that can either be a request or a response
   *
   * The steps:
   * 1. An instance of AuthService is initialized.
   * 2. It checks and adjusts the 'Content-Type' of the outgoing request properly.
   * 3. Sends the potentially modified request to the next HttpHandler in the middleware chain.
   * 4. It catches and handles any errors that occur during request/response handling.
   *
   * Error Handling:
   * - Checks if the error is due to an unauthorized request (HTTP 401 error) or if an unauthorized user attempts
   *   to access a restricted route.
   * - If either is true, it clears the user state, logs the user out and redirects the user to the '/logout' route.
   *
   * Note: Caught errors during request/response handling are re-thrown.
   *
   * @throws HttpErrorResponse | Error - Any caught errors during the request/response handling
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestClone: HttpRequest<any>;

    // Adds application/json as Content-Type to all requests without Content-Type, and removes Content-Type when set
    // explicitly to multipart/form-data so that application/json isn't added and Content-Type is set by the Browser.
    if (!request.headers.has('Content-Type')) {
      requestClone = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    } else if (request.headers.get('Content-Type') === 'multipart/form-data') {
      requestClone = request.clone({
        headers: request.headers.delete('Content-Type'),
      });
    } else {
      requestClone = request.clone();
    }

    return next
      .handle(requestClone)
      .pipe(
        tap(() => {
          // Nothing.
        }),
        catchError((err: HttpErrorResponse | Error) => {
          if ((err instanceof HttpErrorResponse && err.status === 401 && err.url.indexOf('user/login') === -1)
            || (!this.authService.apiToken && this.routeRequiresAuthorization(requestClone.url))) {
            // Clear the user state and go to logout when user is logged out, hits an Api error, and is not on a
            // page that has its own error handling.
            this.userService.clearUserState();
            this.router.navigateByUrl('/logout');
          }

          return throwError(err);
        })
      );
  }

  private routeRequiresAuthorization(url: string): boolean {
    return url !== this.appConfig.getAuthUrl('user/login')
      && !url.includes('integration/lti/authenticate')
      && !url.includes('integration/clever/authenticate')
      && !url.includes('/account/setup?token')
      && !url.includes('/lti-advantage-authenticate')
      && !this.appConfig.isHighlightsPortalUrl;
  }
}
