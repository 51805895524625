import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { AuthService } from '@core/auth.service';
import { FeatureFlagService } from '@core/feature-flag.service';
import { ZbPortalRedesignHelpers } from '@core/services/feature-flagged-features/zb-portal-redesign-helpers';
import { environment } from '@environments/environment';
import { CompanyCode } from '@shared/enums/company-code';
import { FeatureFlagType } from '@shared/enums/feature-flag-type';
import { Helpers } from '@shared/helpers';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { ToastrService } from 'ngx-toastr';
import { skipWhile } from 'rxjs/operators';

@Component({
  selector: 'zbp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public appConfig: AppConfigService,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private router: Router,
    public featureFlagService: FeatureFlagService,
    private toastr: ToastrService,
    private zbRedesignHelpers: ZbPortalRedesignHelpers,
  ) { }

  ngOnInit() {
    this.appConfig.setBrandFromUrl();
    if (this.appConfig.loginBrand === CompanyCode.HighlightsPortal) {
      this.toastr.toastrConfig.positionClass = 'toast-bottom-center';
    }

    this.featureFlagService.featureFlagsLoaded$
      .pipe(
        skipWhile(loaded => !loaded),
      )
      .subscribe(() => {
        if (environment.environment !== 'prod') {
          console.log(`The following features are activated: ${this.featureFlagService.getActiveFlags()}`);
        }
        if (this.featureFlagService.isActive(FeatureFlagType.UseAPIProxyPortal)) {
          console.log(`API Proxy engaged`);
          if (environment.proxyApiUrl) {
            this.appConfig.useProxyApi(true);
          }
        }
      });

    this.authService.authStatus
      .pipe(skipWhile(status => status === null))
      .subscribe((status: boolean) => {
        if (status) {
          // @todo add dimensions - dimension1 = role, dimension2 = grade, dimension3 = schoolid.
          this.angulartics2GoogleAnalytics.startTracking();
        } else if (!Helpers.isAllowedUnauthorizedRoute(this.router.url, ['/'])) {
          // Redirects to logout so full logout routine is run such as hub disconnect/reset.
          this.router.navigateByUrl('/logout');
        }
      });
  }
}
