import { Component, OnInit, Type } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { AuthService } from '@core/auth.service';
import { ZbPortalRedesignHelpers } from '@core/services/feature-flagged-features/zb-portal-redesign-helpers';
import { CompanyCode } from '@shared/enums/company-code';

/**
 * ViewHandlerComponent.
 *
 * Handles which component is returned based on Brand and Feature Flags
 *
 * Implementations can copy the below uncommenting and replacing the selector appropriately. Then copying the
 * componentMap below and setting the component.
 *
 * Remember to add the implementation to the correct module or routing won't work.
 */
@Component({
  template: `<ng-container *ngComponentOutlet="componentToUse | async"></ng-container>`,
})
export abstract class ViewHandlerComponent implements OnInit {
  /**
   * The component to load in the outlet.
   *
   * This is a promise so that standalone components can be supported as well as traditional components.
   */
  componentToUse: Promise<Type<any>> | null;

  /**
   * The component map to use. Implementations should fill out the map with their own components.
   * Entries are optional, so you do not need to provide values for each.
   */
  componentMap = {
    [CompanyCode.HighlightsPortal]: { component: null } || null,
    [CompanyCode.ZBPortal]: { component: null } || null,
    ZbRedesign: { component: null } || null,
  } as any;

  constructor(
    public authService: AuthService,
    private appConfig: AppConfigService,
    private zbPortalRedesignHelpers: ZbPortalRedesignHelpers
  ) { }

  ngOnInit(): void {
    this.initializeComponent();
  }

  /**
   * A default initializer for brand component handling.
   *
   * This can be overridden by the extending component to alter the logic. By default it chooses a component per
   * login brand.
   */
  initializeComponent(): void {
    let brandComponent = this.componentMap[this.appConfig.loginBrand]?.component;

    const useRedesignedIfAvailable = this.appConfig.loginBrand === CompanyCode.ZBPortal
      && this.zbPortalRedesignHelpers.isZbPortal2024Active();

    /**
     * If isZbPortal2024Active Feature Flag is active and there is a redesigned ZB component version,
     * then use the redesigned version for ZB Portal.
     */
    if (useRedesignedIfAvailable && this.componentMap.ZbRedesign?.component) {
      brandComponent = this.componentMap.ZbRedesign?.component;
    }

    if (brandComponent) {
      if (typeof brandComponent?.then === 'function') {
        this.componentToUse = brandComponent;
      }

      this.componentToUse = Promise.resolve(brandComponent);
    }
  }
}
