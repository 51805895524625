import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { AnalyticsService } from '@core/analytics.service';
import { AuthService } from '@core/auth.service';
import { BrowserService } from '@core/browser.service';
import { CacheService } from '@core/cache.service';
import { LoginPageType } from '@shared/enums/login-page-type';

// @DEPRECATED: Delete component when ZBPortal2024 redesign is complete and old design is deprecated.
@Component({
  selector: 'zbp-login-teacher',
  templateUrl: './login.teacher.component.html',
  styleUrls: ['../login.component.scss'],
})
export class TeacherLoginComponent implements OnInit {
  email = '';
  password = '';
  attempts = 0;
  error = null;
  success = null;
  subTitle = 'teachers and school administrators';
  types = LoginPageType;
  type: LoginPageType = LoginPageType.Default;
  loginProcessing = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private titleService: Title,
    private cache: CacheService,
    public browserService: BrowserService,
    private analyticsService: AnalyticsService) {
  }

  get backToZanerBloserLink(): string {
    return 'http://www.zaner-bloser.com';
  }

  onSubmit() {
    this.clearMessages();
    if (this.isLoginValid()) {
      this.loginProcessing = true;
      this.error = null;
      this.attempts++;

      this.authService.login(this.email, this.password)
        .subscribe((res) => {
          if (res.success) {
            this.cache.lastEmailUsed = this.email;
            if (this.type === LoginPageType.Parents) {
              this.cache.setParentAsLastLoginPathUsed();
            } else {
              this.cache.setTeacherAsLastLoginPathUsed();
            }
            this.analyticsService.setUserProperties();
            this.router.navigateByUrl('');
          } else if (res.messages) {
            const [error] = res.messages;
            this.error = error;
            this.loginProcessing = false;
          } else {
            this.error = 'An unknown application error occurred.';
            this.loginProcessing = false;
          }
        }, () => {
          this.loginProcessing = false;
        });
    } else {
      this.error = 'Email and password cannot be blank';
      this.loginProcessing = false;
    }
  }

  ngOnInit() {
    if (this.cache.lastEmailUsed) {
      this.email = this.cache.lastEmailUsed;
    }
    if (this.router.url.includes('parent')) {
      this.type = LoginPageType.Parents;
      this.subTitle = 'parents';
      this.titleService.setTitle('Parent Login | My ZB Portal');
    } else {
      this.subTitle = 'teachers and school administrators';
      this.titleService.setTitle('Teacher Login | My ZB Portal');
    }

  }

  isLoginValid(): boolean {
    return (!!this.email && !!this.password);
  }

  private clearMessages(): void {
    this.error = null;
    this.success = null;
  }
}
