import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { Router } from '@angular/router';
import { IAccountSetup } from '@models/account-setup';
import { Announcement, IAnnouncement } from '@models/announcement';
import { ApiResponse } from '@models/api-response';
import { ApplicationUser } from '@models/application-user';
import { ClassroomIdentifier } from '@models/classroom-id';
import { KeyValuePair } from '@models/key-value-pair';
import { IPagedResponse, PagedResponse } from '@models/paged-response';
import { Quest } from '@models/quest';
import { StudentQRCode } from '@models/student-qr-code';
import { IUser, IUserUpdate, UserPartial } from '@models/user';
import { UserPrefs } from '@models/user/user-prefs';
import { IUserSettings } from '@models/user/user-settings';
import { UserStatusResponse } from '@models/user-response';
import { IUsersRoleRemove } from '@models/users-role-remove';
import { AnnouncementStatus } from '@shared/enums/announcement-status';
import { RoleType } from '@shared/enums/role-type';
import { Helpers } from '@shared/helpers';
import { copyObject } from '@shared/zb-object-helper/object-helper';
import { instantiateApiResponseFromJson } from '@shared/zb-rxjs-operators/rxjs-operators';
import _ from 'lodash';
import { BehaviorSubject, Observable, of, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { AppConfigService } from './appconfig.service';
import { CacheService } from './cache.service';

export interface IUserService {
  userId: string;
  user: IUser;
  hasLicenses: boolean;
  hasAgreed: boolean;
  isMasqueraded: boolean;
  firstLogin: boolean;

  classrooms: ClassroomIdentifier[];
  hasAudioSupport$: BehaviorSubject<boolean>;

  addClassroom(classroom: ClassroomIdentifier): void;
  setUserData(data: UserStatusResponse): void;
  setRoleView(roleType: RoleType): Observable<ApiResponse<boolean>>;
  isAuthorizedForStudentActivity(): boolean;

  /**
   * Update the student audio support.
   *
   * @param {boolean} value - the value to set if resetProfile is true.
   * @param {boolean} resetProfile - whether to update the profileDetail to value.
   */
  resetAudioSupport(value: boolean, resetProfile: boolean): void;
}



@Injectable({
  providedIn: 'root',
})
export class UserService implements IUserService, OnDestroy {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private cache: CacheService,
    private router: Router
  ) {
    this.subscriptions.push(
      this.userPrefs$.subscribe()
    );
  }
  user$: BehaviorSubject<ApplicationUser> = new BehaviorSubject<ApplicationUser>(null);
  updateUserPrefs$: BehaviorSubject<UserPrefs> = new BehaviorSubject<UserPrefs>(null);
  hasAudioSupport$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isGeneratingNewQRCodeBadges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isPrintingNewQRCodeBadges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  studentQRCodes$: BehaviorSubject<StudentQRCode[]> = new BehaviorSubject<StudentQRCode[]>([]);
  hasLicenses = false;
  hasAgreed = false;
  firstLogin = false;

  private subscriptions: Subscription[] = [];
  private _isLoggingOut: boolean = false; // Used to evaluate if userPrefs should be cleared

  // ZBPortal-Api
  private _userId: string = null;
  private _getUserPrefs: boolean = true;
  private _user: IUser;
  private _classrooms: ClassroomIdentifier[] = [];


  filteredUpdatedUserPrefs$: Observable<UserPrefs> = this.updateUserPrefs$.pipe(
    filter(updatedUserPrefs => !!updatedUserPrefs),
    startWith(null),
    distinctUntilChanged(),
    shareReplay()
  );

  userPrefs$: Observable<UserPrefs> = combineLatest([this.filteredUpdatedUserPrefs$, this.user$])
    .pipe(
      filter(([, user]) => !!user),
      map(([updateUserPrefs, user]) => ({ updateUserPrefs, user })),
      switchMap(({ updateUserPrefs, user }) => {
        if (user.profileDetail?.viewingAsRole === RoleType.Teacher) {
          if (this._getUserPrefs && !updateUserPrefs && user.profileDetail?.viewingAsRole) {
            this._getUserPrefs = false;
            return this.getUserPrefsForCurrentRoleType(user.profileDetail.viewingAsRole);
          }
          if (updateUserPrefs && !this._isLoggingOut) {
            this.updateUserPrefs$.next(null);
            return this.postUserPrefsForCurrentRoleType(user.profileDetail.viewingAsRole, updateUserPrefs);
          }
        }
        return of(new UserPrefs());
      }),
      shareReplay()
    );


  /**
   * Current Authenticated User
   */

  get user(): IUser {
    return this._user;
  }

  set user(user: IUser) {
    this._user = user;
    this.user$.next(copyObject(user, ApplicationUser));
  }

  get userId(): string {
    return this._userId;
  }

  set userId(value: string) {
    this._userId = value;
  }

  set isLoggingOut(value: boolean) {
    this._isLoggingOut = value;
  }

  get isMasqueraded(): boolean {
    return this.cache.isMasqueraded;
  }

  set isMasqueraded(value: boolean) {
    this.cache.isMasqueraded = value;
  }

  get classrooms(): ClassroomIdentifier[] {
    return this._classrooms;
  }

  addClassroom(classroom: ClassroomIdentifier): void {
    if (!this._classrooms.some(c => c.classroomId === classroom.classroomId
    && c.integrationId === classroom.integrationId)) {
      this._classrooms.push(classroom);
    }
  }
  removeClassroomById(classroomId: string): void {
    this._classrooms = this._classrooms.filter(c => c.classroomId !== classroomId);
  }

  setUserData(data: UserStatusResponse): Observable<ApplicationUser> {
    this.hasLicenses = data.hasLicenses;
    this.hasAgreed = !data.needsToAcceptLatestEula;
    const userObj = {
      ...data.user,
      classrooms: data.classrooms || [],
      schools: data.schools || [],
      districts: data.districts || [],
      ssoLoginUrl: data.ssoLoginUrl,
    };

    const user = copyObject(userObj, ApplicationUser);

    this.user = user;
    this._classrooms = this._user.classrooms.map(c => ({
      integrationId: null,
      ...(_.pick(c, ['classroomId']) as ClassroomIdentifier),
    }));

    this._userId = this._user.userId;
    this.resetAudioSupport();

    if (this._user.profileDetail?.viewingAsRole === RoleType.Teacher) {
      this._getUserPrefs = true;
    }
    return of(user);
  }

  setRoleView(roleType: RoleType): Observable<ApiResponse<boolean>> {
    const params = [{ key: 'roleType', value: roleType }];
    const endpoint = `/user/${this.userId}/roleview`;
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, endpoint, params);
    return this.http.patch(url, {})
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  isAuthorizedForStudentActivity(): boolean {
    return this._user.isParent || this._user.isDistrictAdmin || this._user.isSchoolAdmin;
  }

  getUserPrefsForCurrentRoleType(roleType: RoleType): Observable<UserPrefs> {
    const url = `${this.appConfig.apiUrl}/user/prefs?roleType=${roleType}`;
    return this.http.get(url)
      .pipe(
        map((res) => {
          if (res === null) {
            return { response: {}, messages: [] };
          // eslint-disable-next-line
        } else if ((res as any).response === null) {
            (res as any).response = {};
          }
          return res;
        }),
        instantiateApiResponseFromJson(UserPrefs),
        map(res => res.response),
      );
  }

  postUserPrefsForCurrentRoleType(roleType: RoleType, userPrefs: UserPrefs): Observable<UserPrefs> {
    const url = `${this.appConfig.apiUrl}/user/prefs?roleType=${roleType}`;
    if (roleType) {
      return this.http.post(url, userPrefs.toJSON())
        .pipe(
          instantiateApiResponseFromJson(UserPrefs),
          map(res => res.response),
        );
    }
    throw new Error('No role type found');
  }

  resetViewingAsRoleIfMultiple() {
    if (this.user.roles.length > 1) {
      this.user.profileDetail.viewingAsRole = null;
    }
  }

  resetAudioSupport(value: boolean = false, resetProfile: boolean = false): void {
    if (this._user.isStudent && !!this._user.profileDetail) {
      if (resetProfile) {
        this._user.profileDetail.enableStudentAudioPrompts = value;
      }
      this.hasAudioSupport$.next(this._user.profileDetail.enableStudentAudioPrompts);
    } else {
      this.hasAudioSupport$.next(value);
    }
  }

  clearUserState(shouldClearFeatureFlags = false, shouldClearMasqueradingData = true): void {
    this.user = copyObject({ locale: 'en-us' }, ApplicationUser);
    this._userId = null;
    this.hasLicenses = false;
    this.isMasqueraded = false;
    this._getUserPrefs = true;
    // Feature flags and masquerading info do not always need removed
    // For example, this allows data and flags to persist to the beginning of masquerading sessions
    this.cache.clearAll(shouldClearFeatureFlags, shouldClearMasqueradingData);
    this.hasAudioSupport$.next(false);
  }

  ensureUserHasAgreedToEula(currentUrl: string) {
    if (!this.hasAgreed && currentUrl !== '/agreement') {
      this.router.navigateByUrl('/agreement');
    }
  }

  private getUserUrl(userId?: string): string {
    const baseUrl = `${this.appConfig.apiUrl}/user`;
    return userId ? `${baseUrl}/${userId}` : baseUrl;
  }

  private getUserAnnouncementUrl(): string {
    return `${this.appConfig.apiUrl}/announcement/status`;
  }

  private getUserPasswordUrl(hasToken: boolean): string {
    // @todo reset password endpoint with token.
    const path = hasToken ? '/user/password/reset' : '/user/password/change';
    return `${this.appConfig.apiUrl}${path}`;
  }

  private getStudentProfileUrl(): string {
    return `${this.appConfig.apiUrl}/student/profile`;
  }

  getAccountSetupInfo(userId: string, unsafeToken: string, resetPassword: boolean): Observable<ApiResponse<IAccountSetup>> {
    // Sets parameter directly on URL rather than using HttpParams. Angular will either pass-through the unsafe token
    // without encoding it or when using encodeURIComponent Angular will incorrectly encode the token. This does not
    // happen when using encodeURIComponent directly in the passed URL.
    const url = `${this.appConfig.apiUrl}/user/${userId}/account/setup?token=${encodeURIComponent(unsafeToken)}&resetPassword=${resetPassword}`;
    return this.http.get<ApiResponse<IAccountSetup>>(url)
      .pipe(
        map(data => new ApiResponse<IAccountSetup>(true, data)),
      );
  }

  /**
   * Create or Update Users
   *
   * Presence of userId (backend inspection) determines backend mode (create or update).
   * Unequal password and current password prompts pwd change.
   *
   * Back-end uses userId to obtain user record.  If no userId, fallback is username.
   *
   * Note: Back-end request object is defined in IUserUpdate (matches API request signature).
   *
   * @params UserPartial | IUserUpdate[] - user(s) to create or update
   * @returns {Observable<ApiResponse<IUser[]>>} ApiResponse - the updated user(s) IUser
   *
   */
  upsertUsers(users: UserPartial[] | IUserUpdate[], schoolId: string = null): Observable<ApiResponse<IUser[]>> {
    const url = schoolId == null ? this.getUserUrl() : `${this.getUserUrl()}?educationalUnitId=${schoolId}`;
    return this.http
      .post<ApiResponse<IUser[]>>(url, users)
      .pipe(
        tap((res: ApiResponse<IUser[]>) => {
          this.processNewQRCodes(res.response);
        }),
        map((res => new ApiResponse<IUser[]>(true, {
          // The Api response has the model of search for user post, not UserStatusResponse.
          response: res.response.map(values => ApplicationUser.fromSearch(values)),
          messages: res.messages,
        }))),
      );
  }

  getUser(userId: string): Observable<ApiResponse<IUser>> {
    return this.http.get<ApiResponse<IUser>>(this.getUserUrl(userId))
      .pipe(
        map(res => new ApiResponse<IUser>(true, {
          response: ApplicationUser.fromSearch(res.response),
          messages: [],
        })),
      );
  }

  updateUserPassword(userId: string, newPassword: string, currentPassword?: string, passResetToken?: string): Observable<ApiResponse<boolean>> {
    const user: any = { UserId: userId, Password: newPassword, ConfirmPassword: newPassword };
    let hasToken = false;
    if (!passResetToken) {
      user.CurrentPassword = currentPassword;
      user.UserId = userId;
    } else {
      user.Token = passResetToken;
      hasToken = true;
    }

    return this.http
      .post<ApiResponse<boolean>>(this.getUserPasswordUrl(hasToken), user)
      .pipe(
        map(res => new ApiResponse<boolean>(true, { response: true, messages: res.messages })),
      );
  }

  getAnnouncements(): Observable<ApiResponse<IAnnouncement[]>> {
    return this.http.get<ApiResponse<IAnnouncement[]>>(this.getUserAnnouncementUrl())
      .pipe(
        map(res => new ApiResponse<IAnnouncement[]>(true, {
          response: res.response.map(values => new Announcement(values)),
          messages: [...res.messages],
        })),
      );
  }

  dismissAnnouncement(id: string): Observable<ApiResponse<IAnnouncement>> {
    const values = { announcementId: id, announcementStatus: AnnouncementStatus.Closed };
    return this.http.patch<ApiResponse<IAnnouncement>>(this.getUserAnnouncementUrl(), values)
      .pipe(
        map(res => new ApiResponse<IAnnouncement>(true, {
          response: new Announcement({ ...res.response }),
          messages: [...res.messages],
        })),
      );
  }

  sortStudents(students: any[]): any[] {
    if (students[0].assignedby_user) {
      students.sort((a, b) => {
        if (a.assignedto_user.firstLastName && b.assignedto_user.firstLastName) {
          const assignmentA = a.assignedto_user.firstLastName.toUpperCase();
          const assignmentB = b.assignedto_user.firstLastName.toUpperCase();
          if (assignmentA < assignmentB) {
            return -1;
          }
          if (assignmentA > assignmentB) {
            return 1;
          }
        }
        return 0;
      });
      return students;
    }
    students.sort((a, b) => {
      if (a.firstLastName && b.firstLastName) {
        const nameA = a.firstLastName.toUpperCase();
        const nameB = b.firstLastName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }
      return 0;
    });
    return students;
  }

  searchUsers(params: KeyValuePair[]): Observable<PagedResponse<IUser[]>> {
    const searchUrl = params
      .reduce((url: string, param: KeyValuePair) => `${url}${param.key}=${param.value}&`, `${this.getUserUrl('search')}?`)
      .replace(/[&?]$/, '');

    return this.http
      .get<IPagedResponse<IUser[]>>(searchUrl)
      .pipe(
        map(res => new PagedResponse<IUser[]>(true, res)),
        map((res) => {
          res.response = res.response.map(u => ApplicationUser.fromSearch(u));
          return res;
        }),
      );
  }

  removeUserRole(userId: string, roleType: RoleType, removeRoleOnly: Boolean, educationalUnitId: string = null): Observable<ApiResponse<boolean>> {
    const fromObject: any = { roleType, removeRoleOnly };
    if (educationalUnitId) {
      fromObject.educationalUnitId = educationalUnitId;
    }
    const params = new HttpParams({ fromObject });
    return this.http.request('delete', `${this.getUserUrl(userId)}/role`, { params })
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  removeUsersRole(data: IUsersRoleRemove): Observable<ApiResponse<boolean>> {
    return this.http.delete(`${this.getUserUrl()}/role`, { body: data })
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  // @todo not yet implemented in the API so catchError will have Method Not Allowed.
  delete(userId: string): Observable<ApiResponse<boolean>> {
    return this.http.request('delete', this.getUserUrl(userId))
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  deleteAllUserMembershipsByRoleType(schoolId: string, roleType?: RoleType): Observable<ApiResponse<boolean>> {
    const url = roleType
      ? `${this.getUserUrl()}/role/educational-unit/${schoolId}?roleType=${roleType}`
      : `${this.getUserUrl()}/role/educational-unit/${schoolId}`;
    return this.http.request('delete', url)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  removeAllStudentsFromClassroom(schoolId: string): Observable<ApiResponse<boolean>> {
    const url = `${this.appConfig.apiUrl}/classroom/student?educationalUnitId=${schoolId}`;
    return this.http.delete(url)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  removeAllStudentsFromClassRoomForTeachers(classroomId: string, schoolId: string): Observable<ApiResponse<boolean>> {
    const url = `${this.appConfig.apiUrl}/classroom/student?classroomId=${classroomId}&educationalUnitId=${schoolId}`;
    return this.http.delete(url)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  unlockAccount(userId: string): Observable<ApiResponse<boolean>> {
    return this.http.patch(`${this.getUserUrl(userId)}/unlock`, {})
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  regenerateStudentQRCodeBadge(userId: string): Observable<ApiResponse<boolean>> {
    return this.http.patch(`${this.appConfig.apiUrl}/user/qr-code`, { userId })
      .pipe(
        tap((res: ApiResponse<StudentQRCode[]>) => {
          this.setStudentQRCodes$(res.response);
        }),
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  regenerateClassroomQRCodeBadges(classroomId: string): Observable<ApiResponse<boolean>> {
    return this.http.patch(`${this.appConfig.apiUrl}/user/qr-code`, { classroomId })
      .pipe(
        tap((res: ApiResponse<StudentQRCode[]>) => {
          this.setStudentQRCodes$(res.response);
        }),
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  export(educationalUnitId: string,
    roleTypes: RoleType,
    userSearchCriteria: string,
    classroomId: string = null,
    districtId: string = null): Observable<Blob> {
    const url = `${this.getUserUrl('export')}`;
    let params = (classroomId === null || classroomId === '')
      ? new HttpParams({
        fromObject: { roleTypes, userSearchCriteria },
      })
      : new HttpParams({
        fromObject: { roleTypes, userSearchCriteria, classroomId },
      });
    if (educationalUnitId != null) {
      params = params.set('educationalUnitId', educationalUnitId);
    }
    if (districtId != null) {
      params = params.set('districtId', districtId);
    }
    return this.http.get(url, { params, responseType: 'blob' })
      .pipe(
      );
  }

  updateLocale(locale: string): Observable<ApiResponse<IUser>> {
    return this.http.patch<ApiResponse<IUser>>(this.getUserUrl('locale'), { locale })
      .pipe(
        map(res => new ApiResponse<IUser>(true, {
          response: copyObject(res.response, ApplicationUser),
          messages: res.messages,
        })),
      );
  }

  /**
   * Updates a student user profile.
   *
   * @param {Partial<IUserSettings>} data a partial object of user settings.
   */
  updateStudentProfile(data: Partial<IUserSettings>): Observable<ApiResponse<boolean>> {
    return this.http.patch(this.getStudentProfileUrl(), data)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  loginUserByQRCode(userId: string, token: string): Observable<ApiResponse<UserStatusResponse>> {
    const badge = { userId, token };
    return this.http.post<ApiResponse<UserStatusResponse>>(`${this.appConfig.apiUrl}/user/qr-code-login`, badge)
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
      );
  }

  updateRecentlyCompletedQuests(recentlyCompletedQuestIds: string[], currentQuest: Quest) {
    // Set the matching questIndex to questId if quest is non-aggregate.  If the questId in the student profile
    // detail is the userGroupId, then the quest is an aggregate quest, and we need to use the userGroupId instead.
    if (currentQuest && recentlyCompletedQuestIds) {
      const recentlyCompletedQuestIndex = recentlyCompletedQuestIds.includes(currentQuest.questId)
        ? recentlyCompletedQuestIds.indexOf(currentQuest.questId)
        : recentlyCompletedQuestIds.indexOf(currentQuest.userGroupId);

      if (recentlyCompletedQuestIndex !== -1) {
        recentlyCompletedQuestIds?.splice(recentlyCompletedQuestIndex, 1);
      }
    }

    const data: Partial<IUserSettings> = {
      recentlyCompletedQuestIds
    };

    return this.updateStudentProfile(data);
  }

  private processNewQRCodes(students: IUser[]): void {
    const tempQRCodes: StudentQRCode[] = [];
    students.forEach((student) => {
      if (student.studentQRCode) {
        tempQRCodes.push(student.studentQRCode);
      }
    });
    if (tempQRCodes.length > 0) {
      this.setStudentQRCodes$(tempQRCodes);
    }
  }

  setIsGeneratingNewQRCodeBadges$(isGeneratingNewQRCodeBadges: boolean): void {
    this.isGeneratingNewQRCodeBadges$.next(isGeneratingNewQRCodeBadges);
  }

  setIsPrintingNewQRCodeBadges$(isPrintingNewQRCodeBadges: boolean): void {
    this.isPrintingNewQRCodeBadges$.next(isPrintingNewQRCodeBadges);
  }

  setStudentQRCodes$(studentQRCodes: StudentQRCode[]): void {
    this.studentQRCodes$.next(studentQRCodes);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
