import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { AuthService } from '@core/auth.service';
import { BrowserService } from '@core/browser.service';
import { ILtiAuthenticateRequest } from '@models/lti-advantage';
import { SharedModule } from '@shared/shared.module';
import { of } from 'rxjs';
import { map, mergeMap, skipWhile } from 'rxjs/operators';


@Component({
  standalone: true,
  selector: 'zbp-lti-advantage-redirect',
  templateUrl: './lti-advantage-redirect.component.html',
  styleUrls: ['../login.component.scss', './lti-advantage-redirect.component.scss'],
  imports: [SharedModule],
})
export class LtiAdvantageRedirectComponent implements OnInit {
  loading = true;
  errors: string[] = [];
  destination: string = '/';

  constructor(
    public browserService: BrowserService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private appConfigService: AppConfigService) { }

  private launchPresentationReturnUrl: string = null;

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        skipWhile(qp => !qp),
        map((qp: Params) => {
          const { id_token, state, companyCodeType, platformId, zbNum } = qp;
          this.errors = qp.message ? [atob(qp.message)] : [];
          const authParam: ILtiAuthenticateRequest = { companyCodeType, platformId, token: id_token, state,  zbNum };
          this.launchPresentationReturnUrl = qp.launchPresentationReturnUrl ? qp.launchPresentationReturnUrl : null;
          this.destination = qp.destination ? qp.destination : '/';
          return authParam;
        }),
        mergeMap(authParam => ((authParam && authParam.token)
          ? this.authService.loginWithLtiAdvantageToken(authParam) : of(null))),
      )
      .subscribe((res) => {
        this.loading = false;
        if (res) {
          if (res.success) {
            this.appConfigService.ltiExternalUrl = this.launchPresentationReturnUrl;
            this.router.navigate([this.destination]);
          } else {
            this.errors = res.messages ? res.messages : ['An unknown error occurred attempting to log you in.'];
          }
        } else {
          this.errors = ['Missing required parameter to log you in.'].concat(this.errors);
        }
      });
  }

}
