import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { AuthService } from '@core/auth.service';
import { interval, Subscription } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';


@Component({
  selector: 'zbp-site-down',
  templateUrl: './site-down.component.html',
  styleUrls: ['./site-down.component.scss'],
})
export class SiteDownComponent implements OnDestroy, OnInit {

  message: string = '';
  title: string = 'Site Maintenance';
  up = true;

  private interval = false;
  private subscription: Subscription;

  constructor(
    private authService: AuthService,
    private appConfig: AppConfigService,
    private router: Router
  ) { }

  ngOnInit() {
    this.appConfig.siteStatus.subscribe((up: boolean) => {
      this.up = up;
      if (this.up) {
        // Redirects back home if the site is up again.
        this.message = '';
        this.router.navigateByUrl('/');
      } else if (!this.interval) {
        this.message = this.appConfig.siteStatusMessage;
        this.title = this.appConfig.siteTitle;
        this.checkStatus();
      }
    });
  }

  ngOnDestroy() {
    this.interval = false;
    if (this.subscription) {
      // Cleans up the subscription on route change otherwise the interval will continue to make
      // requests to check the site status even after the component is destroyed.
      this.subscription.unsubscribe();
      this.subscription.remove(this.subscription);
    }
  }

  // @todo Texas sampling user.
  get backToZanerBloserLink(): string {
    return 'https://www.zaner-bloser.com';
  }

  private checkStatus() {
    this.interval = true;
    this.subscription = interval(120000)
      .pipe(
        takeWhile(() => !this.up),
        mergeMap(() => this.authService.checkTokenStatus()),
      )
      .subscribe((state: boolean) => {
        this.appConfig.setSiteStatus(state);
      });
  }
}
