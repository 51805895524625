import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { ApiResponse } from '@models/api-response';
import { ILtiAuthenticateRequest } from '@models/lti-advantage';
import { UserStatusResponse } from '@models/user-response';
import { Helpers } from '@shared/helpers';
import { BehaviorSubject, interval, Observable, of, Subscription, throwError } from 'rxjs';
import {
  catchError,
  map, mergeMap, skipWhile
} from 'rxjs/operators';
import { AppConfigService } from './appconfig.service';
import { CacheService } from './cache.service';
import { EmailApiService } from './services/application/email/email-api.service';
import { UserService } from './user.service';

export interface IAuthService {
  apiToken: string;
  authStatus: BehaviorSubject<boolean>;

  resetPassword(email: string): Observable<ApiResponse<boolean>>;
  resetActivationOrPassword(email: string): Observable<ApiResponse<boolean>>;
  loginWithLtiToken(token: string): Observable<ApiResponse<UserStatusResponse>>;
  loginWithLtiAdvantageToken(data: ILtiAuthenticateRequest): Observable<ApiResponse<UserStatusResponse>>;
  loginAsStudent(userId: string): Observable<ApiResponse<boolean>>;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthService, OnDestroy {
  // ZBPortal-Api
  private _authStatus: BehaviorSubject<boolean>;
  private _passResetToken: string;

  subscriptions: Subscription[] = [];

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private cache: CacheService,
    private emailApiService: EmailApiService,
    private userService: UserService
  ) {
    this.handleError = this.handleError.bind(this);
    this.subscriptions.push(
      this.initializeTimeout(),
    );
  }

  // Separate Spike will be created to look more into this function.
  // Possibly move to AppConfigService, but we need to figure out how this is being used.
  // AuthService's constructor binds the instance of this Service to this func
  // Cannot find anywhere that is calling this.
  private handleError(error: any): Promise<any> {
    let errorMessage = null;
    try {
      if (error instanceof HttpErrorResponse && error.error) {
        // Error messages are often returned in the body.
        const [errorValue] = error.error;
        errorMessage = errorValue;
      } else if (error.status === 503) {
        this.appConfig.siteStatusMessage = error.error instanceof Array ? error.error[0] : '';
        this.appConfig.setSiteStatus(false);
      } else {
        this.appConfig.siteStatusMessage = '';
        this.appConfig.setSiteStatus(true);
      }
    } catch (ex) {
      console.error(ex);
    }

    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(errorMessage || error.message || error.statusText || error);
  }

  get apiToken(): string {
    return this.cache.coreApiToken;
  }

  get authStatus(): BehaviorSubject<boolean> {
    if (!this._authStatus) {
      this._authStatus = new BehaviorSubject<boolean>(null);
      if (this.apiToken) {
        // Checks that the token is still valid by making a request.
        this.getAuthorizationStatus();
      } else {
        this._authStatus.next(false);
      }

    }
    return this._authStatus;
  }

  get passResetToken(): string {
    return this._passResetToken;
  }

  clearPassResetToken(): void {
    this._passResetToken = null;
  }

  /**
   * Authenticate the user with the supplied credentials, returns success or error
   * This method will also update the CSRF token
   * @param {string} userName: email address if teacher, or userid_schoolid if student
   * @param {string} password
   * @param {boolean} isStudent: whether or not the user is a student
   */
  login(userName: string, password: string, isStudent: boolean = false): Observable<ApiResponse<UserStatusResponse>> {
    const data: any = { userName, password, isStudent, rememberMe: false };
    return this.http.post<ApiResponse<UserStatusResponse>>(this.appConfig.getAuthUrl('user/login'), data)
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
        map((res) => {
          if (res.success) {
            this.userService.isLoggingOut = false;
            if (res.response.user) {
              this.cache.coreApiToken = res.response.token;
              this.setDataAfterLogin(res.response);

              // On login, let the user choose their role if they have multiple
              this.userService.resetViewingAsRoleIfMultiple();
            }
            this.authStatus.next(true);
          }
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<UserStatusResponse>(false, err.error));
        })
      );
  }

  logout(): void {
    this.userService.isLoggingOut = true;

    if (this.userService.userId) {
      this.logoutUser().subscribe();
    }

    this.userService.clearUserState();
    this.appConfig.clearCustomData();
    this.userService.isLoggingOut = false;
  }

  loginWithLtiToken(token: string): Observable<ApiResponse<UserStatusResponse>> {
    const data = { token };
    return this.http.post<ApiResponse<UserStatusResponse>>(
      this.appConfig.getAuthUrl('integration/lti/authenticate'),
      data)
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
        map((res) => {
          if (res.success) {
            this.userService.isLoggingOut = false;
            if (res.response.user) {
              this.cache.coreApiToken = res.response.token;
              this.setDataAfterLogin(res.response);
            }
            this.authStatus.next(true);
          }
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<UserStatusResponse>(false, err.error));
        })
      );
  }

  loginWithLtiAdvantageToken(data: ILtiAuthenticateRequest): Observable<ApiResponse<UserStatusResponse>> {
    const item: any = {
      idToken: data.token,
      state: data.state,
      platformId: data.platformId,
      companyCodeType: data.companyCodeType,
      zbNum: data.zbNum,
    };

    return this.http.post<ApiResponse<UserStatusResponse>>(
      this.appConfig.getAuthUrl('lti-advantage-authenticate'),
      item)
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
        map((res) => {
          if (res.success) {
            this.userService.isLoggingOut = false;
            if (res.response.user) {
              this.cache.coreApiToken = res.response.token;
              this.setDataAfterLogin(res.response);
            }
            this.authStatus.next(true);
          }
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<UserStatusResponse>(false, err.error));
        })
      );
  }

  loginAsStudent(userId: string): Observable<ApiResponse<boolean>> {
    return this.http.post(this.appConfig.getAuthUrl(`associated-user/${userId}/login`), '')
      .pipe(
        map((res: ApiResponse<UserStatusResponse>) => res.response),
        map((status: UserStatusResponse) => {
          const response = new ApiResponse<boolean>(true, { response: true, messages: [] });
          if (status.user) {
            this.userService.clearUserState(false);
            this.appConfig.clearCustomData();
            this.userService.isLoggingOut = false;

            this.cache.coreApiToken = status.token;
            this.setDataAfterLogin(status);
            // Do emit auth status, so root can change body classes.
            this._authStatus.next(true);
          } else {
            response.success = false;
            response.response = false;
            Helpers.setMessages(['User data not returned']);
          }

          return response;
        }),
      );
  }

  checkTokenStatus(): Observable<boolean> {
    return this.http.get<ApiResponse<UserStatusResponse>>(this.appConfig.getAuthUrl('user/status'))
      .pipe(
        map(() => true),
        // If an error occurs the Api is having CORS issues if we don't get a valid response.
        catchError((err: HttpErrorResponse) => of(err.type === HttpEventType.ResponseHeader))
      );
  }

  getAuthorizationStatus(): void {
    this.http.get<ApiResponse<UserStatusResponse>>(this.appConfig.getAuthUrl('user/status'))
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
        map((res) => {
          if (res.success) {
            this.setDataAfterLogin(res.response);
            this.appConfig.inSkofApp = !!window.zbLoginInterop || false;

          }
          return res.success;
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401 || err.status === 403) {
            this.userService.clearUserState();
            this.appConfig.clearCustomData();
            this.authStatus.next(false);
            this.userService.isLoggingOut = false;
          }
          return throwError(err);
        })
      )
      .subscribe((status) => {
        this._authStatus.next(status);
      });
  }

  private setDataAfterLogin(data: UserStatusResponse) {
    this.userService.setUserData(data);
    this.appConfig.setCustomUrls(data);
  }

  // Initializes an interval to poll to get current authorization status and apiToken value. The apiToken
  // may have been removed by a separate front end application instance.
  private initializeTimeout(): Subscription {
    return interval(2500)
      .pipe(
        mergeMap(() => this.authStatus.asObservable()),
        skipWhile(status => status === null)
      )
      .subscribe((status: boolean) => {
        if (status && (!this.apiToken || this.apiToken.length === 0)) {
          this.authStatus.next(false);
        }
      });
  }

  /**
   * Triggers a password reset email for a teacher.  Not intended for use with students
   */
  resetPassword(email: string): Observable<ApiResponse<boolean>> {
    return this.emailApiService.resetPassword(email)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<boolean>(false, err.error));
        })
      );
  }

  resetActivationOrPassword(email: string): Observable<ApiResponse<boolean>> {
    const params = [{ key: 'toAddress', value: email }];
    const endpoint = '/user/email/resend';
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, endpoint, params);
    return this.http.patch(url, {})
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  resendUserEmail(userId: string): Observable<ApiResponse<boolean>> {
    const params = [{ key: 'userId', value: userId }];
    const endpoint = `/user/email`;
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, endpoint, params);
    return this.http.patch(url, {})
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  resendFromEmailLog(email: string): Observable<ApiResponse<boolean>> {
    const params = [];
    const endpoint = `/email/email-log/${email}`;
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, endpoint, params);
    return this.http.patch(url, {})
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  logoutUser(): Observable<ApiResponse<boolean>> {
    return this.http.post(this.appConfig.getAuthUrl('user/logout'), null)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
